import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import {
  displayFormattedValue,
  generateSubheaderLabel,
  getAvailableIdentifier,
  getMatchingPropForVehicleName,
  stripNonAlphaCharacters,
} from './tableUtils';
import i18n from '../localization';
import {
  headerTitleToTranslationKey,
  vehicleIdentifierToTranslationKey,
} from './convertTranslationKeys';

type Props = {
  sheetTitle: string;
  tableData: TableDataStructure;
  tableWidgetColumns: Array<{ columnName: string; subColumns: Array<string> }> | undefined;
  staffDetails: StaffDetails;
  interval: {
    startDate: string;
    endDate: string;
  };
};

export const createExcelWorkbook = async ({
  sheetTitle,
  tableData,
  tableWidgetColumns = [],
  staffDetails,
  interval,
}: Props) => {
  const dateTimeFormat = 'YYYY-MM-DD HH:mm';
  const workbook = new ExcelJS.Workbook();
  let date = new Date();
  workbook.creator = 'Scania - Vehicle Performance';
  workbook.created = date;
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible',
    },
  ];

  const reportSheet = workbook.addWorksheet(sheetTitle, {
    properties: { defaultColWidth: 20 },
  });

  const customerNameRow = reportSheet.getRow(1);
  customerNameRow.height = 60;
  customerNameRow.alignment = { vertical: 'middle' };
  customerNameRow.getCell(1).value = staffDetails.customer?.name || '';

  const overviewPeriodRow = reportSheet.getRow(2);
  overviewPeriodRow.height = 60;
  overviewPeriodRow.alignment = { vertical: 'middle' };
  overviewPeriodRow.getCell(1).value = `${i18n.t(
    'HEADER_ReportType2'
  )}: ${dayjs(interval.startDate).format(dateTimeFormat)} - ${dayjs(
    interval.endDate
  ).format(dateTimeFormat)}`;

  const headerRow = reportSheet.getRow(4);

  const createTableHeaderRow = () => {
    const tableHeaderRows: any[] = [];

    const translatedEquipmentKey = { param: i18n.t('Utrustning'), count: 1 };
    const dashboardParams = tableWidgetColumns.map(
      (columnDetails) => ({
          param: stripNonAlphaCharacters(
            i18n.t(headerTitleToTranslationKey(columnDetails.columnName))
          ),
          count: columnDetails.subColumns.length,
        })
    );

    tableHeaderRows.push(translatedEquipmentKey, ...dashboardParams);
    return tableHeaderRows;
  };

  const createTableUnitsRow = () => {
    const tableUnitsRow: string[] = [];

    const translatedVehicleIdentifier = i18n.t(
      vehicleIdentifierToTranslationKey(
        getMatchingPropForVehicleName(staffDetails.vehicleIdentifier)
      )
    );

    const dashboardUnits = tableData.equipments[0]?.parameters ? tableWidgetColumns.map(columnDetails => {
      return columnDetails.subColumns?.map(subColumn => {
        const currentMetric = tableData.equipments[0]?.parameters?.find((parameter: Metric) => parameter.name.toLowerCase() === subColumn.toLowerCase());
        return typeof currentMetric !== 'undefined' ? generateSubheaderLabel(currentMetric) : '';
      })
    }) : [];

    tableUnitsRow.push(translatedVehicleIdentifier, ...dashboardUnits.flat(3));
    return tableUnitsRow;
  };

  const createTableAverageRow = () => {
    const tableAverageRow = [];

    const averageValues = tableData?.average ?  tableWidgetColumns.map(columnDetails => {
      return columnDetails.subColumns?.map(subColumn => {
        const currentMetric = tableData.average?.find((parameter: Metric) => parameter.name.toLowerCase() === subColumn.toLowerCase());
        return typeof currentMetric !== 'undefined' ? displayFormattedValue(currentMetric) : '';
      })
    }) : [];

    tableAverageRow.push(i18n.t('TF_FleetAverage'), ...averageValues.flat(3));
    return tableAverageRow;
  }

  const createTableBodyRows = () => {
    const tableBodyRows: string[][] = [];

    tableData?.equipments?.forEach((singleVehicle: TableWidgetEquipment) => {
      const currentVehicle = getAvailableIdentifier(
        getMatchingPropForVehicleName(staffDetails.vehicleIdentifier),
        singleVehicle.equipmentInfo
      );

      const currentVehicleParamsValues = tableWidgetColumns.map(columnDetails => {
        return columnDetails?.subColumns?.length > 0 ? columnDetails.subColumns?.map(subColumn => {
          const currentMetric = singleVehicle?.parameters?.find((parameter: Metric) => parameter.name.toLowerCase() === subColumn.toLowerCase());
          return typeof currentMetric !== 'undefined' ? displayFormattedValue(currentMetric) : '';
        }) : []
      });

      tableBodyRows.push([
        currentVehicle,
        ...currentVehicleParamsValues.flat(3),
      ]);
    });

    return tableBodyRows;
  };

  const generateTableHeader = () => {
    const tableHeaderCells = createTableHeaderRow();
    let currentColumnNumber = 1;

    for (let i = 0; i < tableHeaderCells.length; i++) {
      if (tableHeaderCells[i].count === 1) {
        let cell = reportSheet.getCell(headerRow.number, currentColumnNumber);
        cell.value = tableHeaderCells[i].param;
        currentColumnNumber++;
      } else {
        reportSheet.mergeCells(
          headerRow.number,
          currentColumnNumber,
          headerRow.number,
          currentColumnNumber + tableHeaderCells[i].count - 1
        );
        reportSheet.getCell(headerRow.number, currentColumnNumber).value =
          tableHeaderCells[i].param;
        currentColumnNumber += tableHeaderCells[i].count;
      }
    }

    headerRow.alignment = { vertical: 'middle' };
    headerRow.font = { bold: true, underline: 'single' };
  };

  // -----Table generation-----

  generateTableHeader();

  const tableUnitsRow = createTableUnitsRow();
  const unitsRow = reportSheet.addRow(tableUnitsRow);
  unitsRow.alignment = { horizontal: 'right' };
  unitsRow.getCell(1).alignment = { horizontal: 'left' };
  
  const tableAverageRow = createTableAverageRow();
  const averageRow = reportSheet.addRow(tableAverageRow);
  averageRow.alignment = { horizontal: 'right' };
  averageRow.getCell(1).alignment = { horizontal: 'left' };
  averageRow.eachCell({ includeEmpty: false }, (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern:'solid',
      fgColor:{argb:'FFCDD1DC'}
    };
  });

  const tableBodyRows = createTableBodyRows();
  const bodyRows = reportSheet.addRows(tableBodyRows);
  bodyRows.forEach((row) => {
    row.alignment = { horizontal: 'right' };
    row.getCell(1).alignment = { horizontal: 'left' };
  });

  // -----Table download-----

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;';
    let EXCEL_EXTENSION = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });

    FileSaver.saveAs(
      blob,
      `${i18n.t('Översikt')} ${dayjs(interval.startDate).format(
        dateTimeFormat
      )} - ${dayjs(interval.endDate).format(dateTimeFormat)}` + EXCEL_EXTENSION
    );
  } catch (e) {
    console.error(e);
  }
};
