import { useEffect, useRef, useState } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker'
import { TdsButton, TdsChip, TdsIcon } from '@scania/tegel-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask'
import * as Yup from 'yup';

import { getDateFnsMappedLocale, importLocale } from '../../utils/datepickerLocaleMap';
import '../../styles/DateTimeRangePicker.css'

type DateTimeRangePickerProps = {
  periodStart: string | null;
  periodEnd: string | null;
  staffStartOfWeek: 'sunday' | 'monday';
  staffLanguage: string;
  searchNewInterval: Function;
};

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';
const dateFnsFormat = 'yyyy/MM/dd, HH:mm'

const intervals = [
  {
    id: 'option_today',
    label: 'Today',
    translationKey: "Idag"
  },
  {
    id: 'option_last_week',
    label: 'Last week',
    translationKey: "FöregåendeVecka"
  },
  {
    id: 'option_this_month',
    label: 'This month',
    translationKey: "DennaMånad"
  },
  {
    id: 'option_last_month',
    label: 'Last month',
    translationKey: "FörraMånaden"
  },
  {
    id: 'option_this_year',
    label: 'This year',
    translationKey: "VP_ThisYear"
  },
  {
    id: 'option_last_year',
    label: 'Last year',
    translationKey: "VP_LastYear"
  },
]

const formatInputTime = (time: number) => {
  if (time >= 0 && time <= 9) return time.toString().padStart(2, '0')
  return time.toString()
}

const determineValidHour = (hour: string) => !isNaN(Number(hour)) && Number(hour) >= 0 && Number(hour) <= 23
const determineValidMinutes = (minutes: string) => !isNaN(Number(minutes)) && Number(minutes) >= 0 && Number(minutes) <= 59

const DateTimeRangePicker = ({
  periodStart,
  periodEnd,
  staffStartOfWeek = 'monday',
  staffLanguage = 'en-GB',
  searchNewInterval,
}: DateTimeRangePickerProps) => {
  const { t } = useTranslation();
  const datePickerRef = useRef<DatePicker>(null)
  const [startDate, setStartDate] = useState(dayjs(periodStart).toDate())
  const [endDate, setEndDate] = useState(dayjs(periodEnd).toDate())
  const [checkedInterval, setCheckedInterval] = useState<string | null>(null)
  const [validationError, setValidationError] = useState<any>();
  const [locale, setLocale] = useState('')

 useEffect(() => {
    const importUserLocale = async () => {
      const userLocale = getDateFnsMappedLocale(staffLanguage)
      try{
          const locale = await importLocale(staffLanguage)
          registerLocale(userLocale, locale)
          setLocale(userLocale)
      } catch (error) {
        console.error(`Error loading locale: ${userLocale}`, error)
      }
    }

    importUserLocale()
  }, [staffLanguage])

  const dateRangeSchema = Yup.object({
    start: Yup.date().required(t("EttStartdatumKrävs")),
    end: Yup.date().required(t("EttSlutdatumKrävs"))
  }).test(
    'valid-date-range',
    function (value) {
      const { start, end } = value
      const startDate = dayjs(start);
      const endDate = dayjs(end);
      const twoYearsAgo = dayjs().subtract(2, 'years').startOf('year');
      const now = dayjs();

      if (!startDate.isValid() || !endDate.isValid()) {
        return this.createError({ message: t("OgiltigtDatumformat") });
      }

      if (startDate.isBefore(twoYearsAgo)) {
        return this.createError({
          message: t('AngeEttVärdeMellan_0_Och_1_')
            .replace('{1}', dayjs(twoYearsAgo).format('YYYY/MM/DD HH:mm'))
            .replace('{2}', t('Idag').toLowerCase())
        });
      }

      if (endDate.isBefore(startDate)) {
        return this.createError({ message: t("SlutdatumMåsteVaraSenareÄnStartdatum") });
      }

      if (endDate.isAfter(now)) {
        return this.createError({ message: t("DatumetKanInteInfallaIFramtiden") });
      }

      return true;
    }
  );

  const updateInterval = (intervalType: string) => {
    let start;
    let end;

    switch (intervalType) {
      case 'option_today':
        start = dayjs().startOf('day')
        end = dayjs()
        break;
      case 'option_last_week':
        const dayOfWeek = dayjs().day()

        start = staffStartOfWeek.toLowerCase() === 'sunday'
          ? dayjs()
            .subtract(7 + dayOfWeek, 'days')
            .startOf('day')
          : dayjs()
            .subtract(6 + dayOfWeek, 'days')
            .startOf('day')
        end = start.add(6, 'days').endOf('day')
        break;
      case 'option_this_month':
        start = dayjs().startOf('month')
        end = dayjs()
        break;
      case 'option_last_month':
        const lastMonth = dayjs().subtract(1, 'month')
        start = lastMonth.startOf('month')
        end = lastMonth.endOf('month')
        break;
      case 'option_this_year':
        start = dayjs().startOf('year')
        end = dayjs()
        break;
      case 'option_last_year':
        const lastYear = dayjs().subtract(1, 'year')
        start = lastYear.startOf('year')
        end = lastYear.endOf('year')
        break;
      default:
        return;
    }

    setStartDate(dayjs(start).toDate())
    setEndDate(dayjs(end).toDate())
    setValidationError(undefined)
  }

  const closeDatePicker = () => {
    if (datePickerRef.current) {
      setStartDate(dayjs(periodStart).toDate())
      setEndDate(dayjs(periodEnd).toDate())
      setCheckedInterval(null)
      setValidationError(undefined)

      datePickerRef.current.setOpen(false)
    }
  }

  const DatePickerContainer = ({ className, children }: any) => {
    const [startHour, setStartHour] = useState<string>(formatInputTime(dayjs(startDate).hour()))
    const [startMinutes, setStartMinutes] = useState<string>(formatInputTime(dayjs(startDate).minute()))
    const [endHour, setEndHour] = useState<string>(formatInputTime(dayjs(endDate).hour()))
    const [endMinutes, setEndMinutes] = useState<string>(formatInputTime(dayjs(endDate).minute()))
    
    const shouldApplyBeDisabled = !!validationError || !determineValidHour(startHour) || !determineValidHour(endHour) || !determineValidMinutes(startMinutes) || !determineValidMinutes(endMinutes)
    
    const handleArrowHourChange = (type: 'start' | 'end', changeDirection: 'increase' | 'decrease') => {
      if((type === 'start' && !dayjs(startDate).isValid()) || (type === 'end' && !dayjs(endDate).isValid())) return;

      const setDate = type === 'start' ? setStartDate : setEndDate;
      const setHour = type === 'start' ? setStartHour : setEndHour;
      setCheckedInterval(null)

      setHour(prevHour => {
        const hour = Number(prevHour);

        switch (changeDirection) {
          case 'increase':
            return hour === 23 ? formatInputTime(0) : formatInputTime(hour + 1)
          case 'decrease':
            return hour === 0 ? formatInputTime(23) : formatInputTime(hour - 1)
          default:
            return prevHour;
        }
      })

      setDate(prevDate => {
        const date = dayjs(prevDate);
        const hour = date.hour();

        switch (changeDirection) {
          case 'increase':
            return date.set('hour', hour === 23 ? 0 : hour + 1).toDate();
          case 'decrease':
            return date.set('hour', hour === 0 ? 23 : hour - 1).toDate();
          default:
            return prevDate;
        }
      });

      setValidationError(undefined)
    };

    const handleArrowMinutesChange = (type: 'start' | 'end', changeDirection: 'increase' | 'decrease') => {
      if((type === 'start' && !dayjs(startDate).isValid()) || (type === 'end' && !dayjs(endDate).isValid())) return;

      const setDate = type === 'start' ? setStartDate : setEndDate;
      const setMinutes = type === 'start' ? setStartMinutes : setEndMinutes;
      setCheckedInterval(null)

      setMinutes(prevMinutes => {
        const minutes = Number(prevMinutes);

        switch (changeDirection) {
          case 'increase':
            return minutes === 59 ? formatInputTime(0) : formatInputTime(minutes + 1)
          case 'decrease':
            return minutes === 0 ? formatInputTime(59) : formatInputTime(minutes - 1)
          default:
            return prevMinutes;
        }
      })

      setDate(prevDate => {
        const date = dayjs(prevDate);
        const minute = date.minute();

        switch (changeDirection) {
          case 'increase':
            return date.set('minute', minute === 59 ? 0 : minute + 1).toDate();
          case 'decrease':
            return date.set('minute', minute === 0 ? 59 : minute - 1).toDate();
          default:
            return prevDate;
        }
      });

      setValidationError(undefined)
    };

    const onHourInputChange = (type: 'start' | 'end', value: string) => {
      if((type === 'start' && !dayjs(startDate).isValid()) || (type === 'end' && !dayjs(endDate).isValid())) {
        setValidationError(t("OgiltigtDatumformat"))
        return;
      }

      const setHour = type === 'start' ? setStartHour : setEndHour
      setHour(value)
      setCheckedInterval(null)

      if (determineValidHour(value)) {
        const hour = Number(value)
        const setDate = type === 'start' ? setStartDate : setEndDate;

        setDate(prevDate => dayjs(prevDate).set('hour', hour).toDate())
      }
    }

    const onMinutesInputChange = (type: 'start' | 'end', value: string) => {
      if((type === 'start' && !dayjs(startDate).isValid()) || (type === 'end' && !dayjs(endDate).isValid())) {
        setValidationError(t("OgiltigtDatumformat"))
        return;
      }

      const setMinutes = type === 'start' ? setStartMinutes : setEndMinutes
      setMinutes(value)
      setCheckedInterval(null)

      if (determineValidMinutes(value)) {
        const minutes = Number(value)
        const setDate = type === 'start' ? setStartDate : setEndDate;

        setDate(prevDate => dayjs(prevDate).set('minutes', minutes).toDate())
      }
    }

    const onDateApply = () => {
      if (!shouldApplyBeDisabled) {
        const dates = {
          start: startDate,
          end: endDate
        }

        dateRangeSchema
          .validate(dates)
          .then(() => {
            setValidationError(undefined)
            datePickerRef.current?.setOpen(false)
            searchNewInterval({ startDate: dayjs(startDate).format(dateTimeFormat), endDate: dayjs(endDate).format(dateTimeFormat) })
          })
          .catch(err => {
            setValidationError(err.errors[0])
          });
      }
    }

    return (
      <div>
        <CalendarContainer className={className}>
          <div style={{ display: "flex", flexDirection: 'column' }}>
            <div style={{ display: "flex" }}>
              <div className='datepicker-chip-container'>
                {intervals.map(interval => (
                  <TdsChip key={interval.id} type='radio' name={interval.id} chipId={interval.id} checked={checkedInterval === interval.id} onClick={() => { setCheckedInterval(interval.id); updateInterval(interval.id) }}>
                    <span slot='label' className='interval-chip'>{t(interval.translationKey)}</span>
                  </TdsChip>
                ))}
              </div>
              <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <div style={{padding: "12px 12px 0 12px"}}>
                  {children}
                </div>
                <div className='timepicker-container'>

                  <div className='timepicker-single-input-container'>
                    <TdsIcon name='chevron_up' class={`timepicker-tegel-icon ${!dayjs(startDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowHourChange('start', 'increase')} />
                    <InputMask disabled={!dayjs(startDate).isValid()} className={`timepicker-single-input ${dayjs(startDate).isValid() && !determineValidHour(startHour) ? 'timepicker-single-input-error' : ''}`} value={startHour} onChange={(e: any) => onHourInputChange('start', e.target.value)} mask='99' />
                    <TdsIcon name='chevron_down' class={`timepicker-tegel-icon ${!dayjs(startDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowHourChange('start', 'decrease')} />
                  </div>
                  <span className='timepicker-span'>:</span>

                  <div className='timepicker-single-input-container'>
                    <TdsIcon name='chevron_up' class={`timepicker-tegel-icon ${!dayjs(startDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowMinutesChange('start', 'increase')} />
                    <InputMask disabled={!dayjs(startDate).isValid()} className={`timepicker-single-input ${dayjs(startDate).isValid() && !determineValidMinutes(startMinutes) ? 'timepicker-single-input-error' : ''}`} value={startMinutes} onChange={(e: any) => onMinutesInputChange('start', e.target.value)} mask='99' alwaysShowMask />
                    <TdsIcon name='chevron_down' class={`timepicker-tegel-icon ${!dayjs(startDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowMinutesChange('start', 'decrease')} />
                  </div>
                  <span className='timepicker-span' style={{ margin: '0 8px' }}>-</span>

                  <div className='timepicker-single-input-container'>
                    <TdsIcon name='chevron_up' class={`timepicker-tegel-icon ${!dayjs(endDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowHourChange('end', 'increase')} />
                    <InputMask disabled={!dayjs(endDate).isValid()} className={`timepicker-single-input ${dayjs(endDate).isValid() && !determineValidHour(endHour) ? 'timepicker-single-input-error' : ''}`} value={endHour} onChange={(e: any) => onHourInputChange('end', e.target.value)} mask='99' alwaysShowMask />
                    <TdsIcon name='chevron_down' class={`timepicker-tegel-icon ${!dayjs(endDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowHourChange('end', 'decrease')} />
                  </div>
                  <span className='timepicker-span'>:</span>

                  <div className='timepicker-single-input-container'>
                    <TdsIcon name='chevron_up' class={`timepicker-tegel-icon ${!dayjs(endDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowMinutesChange('end', 'increase')} />
                    <InputMask disabled={!dayjs(endDate).isValid()} className={`timepicker-single-input ${dayjs(endDate).isValid() && !determineValidMinutes(endMinutes) ? 'timepicker-single-input-error' : ''}`} value={endMinutes} onChange={(e: any) => onMinutesInputChange('end', e.target.value)} mask='99' alwaysShowMask />
                    <TdsIcon name='chevron_down' class={`timepicker-tegel-icon ${!dayjs(endDate).isValid() && 'timepicker-tegel-icon--disabled'}`} size='20' onClick={() => handleArrowMinutesChange('end', 'decrease')} />
                  </div>

                </div>
                { validationError && <span className='datepicker-validation-message'>{validationError}</span> }
              </div>
            </div>
            <div className='actions-container'>
              <div className='buttons-container'>
                <TdsButton variant='secondary' fullbleed text={t("Avbryt")} size="sm" onClick={closeDatePicker} />
                <TdsButton variant="primary" fullbleed disabled={shouldApplyBeDisabled} text={t("Verkställ")} size="sm" onClick={onDateApply} />
              </div>
            </div>

          </div>
        </CalendarContainer>
      </div>
    )
  }

  const onCalendarChange = (dates: any) => {
    setCheckedInterval(null)

    const [start, end] = dates;
    setStartDate(start);

    if (end) {
      const now = dayjs();
      const endDate = dayjs(end);

      if (endDate.isSame(now, 'day')) {
        setEndDate(endDate.hour(now.hour()).minute(now.minute()).second(now.second()).toDate());
      } else {
        setEndDate(endDate.hour(23).minute(59).second(59).toDate());
      }

      setValidationError(undefined)
    } else {
      setEndDate(end);
    }
  };

  const onDatePickerInputChange = (e: any) => {
    if (e.type === 'change' && e.nativeEvent.type === 'input') {
      setCheckedInterval(null)
      const [start, end] = e.target.value.split(' - ')

      const dates = {
        start: dayjs(start),
        end: dayjs(end)
      }

      dateRangeSchema
        .validate(dates)
        .then(() => {
          setValidationError(undefined)
          setStartDate(dayjs(start).toDate())
          setEndDate(dayjs(end).toDate())
        }).catch(err => setValidationError(err.errors[0]))
    }
  }

  return (
    <div className='datetimerangepicker-container'>
      <label className='datepicker-tegel-input-label'>{t("Datumintervall")}</label>
      <DatePicker
        ref={datePickerRef}
        openToDate={startDate}
        locale={locale}
        showIcon
        icon={<TdsIcon name='calendar' size='20px' />}
        toggleCalendarOnIconClick
        popperPlacement='bottom-end'
        customInput={
          <InputMask
            className='datepicker-tegel-input'
            mask='9999/99/99, 99:99 - 9999/99/99, 99:99'
          />
        }
        selected={startDate}
        onChange={onCalendarChange}
        onChangeRaw={onDatePickerInputChange}
        onBlur={closeDatePicker}
        selectsRange
        showPopperArrow={false}
        startDate={startDate}
        endDate={endDate}
        includeDateIntervals={[{ start: dayjs().subtract(2, 'years').startOf('year').toDate(), end: dayjs().toDate() }]}
        shouldCloseOnSelect={false}
        calendarContainer={DatePickerContainer}
        dateFormat={dateFnsFormat}
        calendarStartDay={staffStartOfWeek.toLowerCase() === 'sunday' ? 0 : 1}
        disabledKeyboardNavigation
      />
    </div>
  )
}

export default DateTimeRangePicker