import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';
const defaultStartDate = dayjs()
  .subtract(30, 'day')
  .startOf('hour')
  .format(dateTimeFormat);
const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);

const initialState = {
  selectedDates: {
    overview: {
      periodStart: defaultStartDate,
      periodEnd: defaultEndDate,
    },
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeOverviewStartDate(state, action) {
      state.selectedDates.overview.periodStart = action.payload;
    },
    changeOverviewEndDate(state, action) {
      state.selectedDates.overview.periodEnd = action.payload;
    },
  },
});

export const {
  changeOverviewStartDate,
  changeOverviewEndDate,
} = settingsSlice.actions;

export default settingsSlice.reducer;
