import {
  SetStateAction,
  Dispatch,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';

import '../../styles/GeneralStyles.css';

type Props = {
  selectedOptions: string[];
  onSelection: Dispatch<SetStateAction<string[]>>;
  externalEquipmentReference: string | null;
};

const FuelTypeDropdown = ({ selectedOptions, onSelection, externalEquipmentReference }: Props) => {
  const { t } = useTranslation();
  const fuelTypeDropdownRef = useRef<HTMLTdsDropdownElement>(null);

  const handleFuelTypeSelection = useCallback(
    (e: any) => {
      let optionsCopy = [...selectedOptions];

      if (e.detail.selected && !optionsCopy.includes(e.detail.value)) {
        optionsCopy.push(e.detail.value);
      } else {
        optionsCopy = optionsCopy.filter((option) => option !== e.detail.value);
      }

      onSelection(optionsCopy);
    },
    [onSelection, selectedOptions]
  );

  useEffect(() => {
    const fuelTypeDropdown = fuelTypeDropdownRef.current;
    if (!fuelTypeDropdown) return;

    fuelTypeDropdown.addEventListener('tdsSelect', handleFuelTypeSelection);

    return () => {
      fuelTypeDropdown.removeEventListener(
        'tdsSelect',
        handleFuelTypeSelection
      );
    };
  }, [handleFuelTypeSelection]);

  return (
    <div className='typeDropdownContainer'>
      <TdsDropdown
        ref={fuelTypeDropdownRef}
        name='fuelTypes'
        multiselect
        modeVariant='primary'
        size='md'
        placeholder={t('Välj')}
        label={t('TH_Bränsletyp')}
        label-position='outside'
        disabled={externalEquipmentReference !== null}>
        <TdsDropdownOption key='diesel' value='diesel'>
          {t('Diesel')}
        </TdsDropdownOption>
        <TdsDropdownOption key='ethanol' value='ethanol'>
          {t('Etanol')}
        </TdsDropdownOption>
        <TdsDropdownOption key='gas' value='gas'>
          {t('Gas')}
        </TdsDropdownOption>
        <TdsDropdownOption key='electric' value='electric'>
          {t('Electricitet')}
        </TdsDropdownOption>
      </TdsDropdown>
    </div>
  );
};

export default FuelTypeDropdown;
