import { getAvailableIdentifier, getMatchingPropForVehicleName } from "./report/tableUtils";

export const generateSecureNumber = () => {
  const numbersList = new Uint32Array(10);
  const secureNumbers = crypto.getRandomValues(numbersList);

  return secureNumbers[Math.floor(Math.random()*10)];
}

export const getIdentifierForEqRef = (externalEquipmentRef: string | null, equipmentsData: any, staffDataIdentifier: string) => {
  if (staffDataIdentifier && equipmentsData && externalEquipmentRef) {
    const selectedEquipment = equipmentsData.equipments?.filter((equipment: TableWidgetEquipment ) => equipment?.externalEquipmentReference === externalEquipmentRef);
    
    if (selectedEquipment && selectedEquipment.length) {
      const selectedEquipmentIdentifier = getAvailableIdentifier(
        getMatchingPropForVehicleName(staffDataIdentifier), selectedEquipment[0]?.equipmentInfo
      );

      return selectedEquipmentIdentifier;
    }
  }

  return '';
}